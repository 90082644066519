.bg {
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.bg h1,
.bg p {
  margin: 0;
}

.bg p {
  font-size: calc(8px + 2vmin);
}
